import React from 'react';

import './Separator.scss';

const CLASS = 'el-Separator';

export const Separator = () => {
	return (
	  <div className={CLASS}>
		
	  </div>
	)
}
export default Separator;
